.cardFormText{
    font-family: 'Newsreader', serif;
    font-size: 20px;
    margin: 15px;
}
.cardFormTitle{
    margin-top: 30px;
    margin-left: 15px;
    font-family: 'Newsreader', serif;
    font-size: 24px;
}
.emailUI{
    width: 600px;
    margin: 10px;
    margin-left: -500px;
    filter: drop-shadow(0px 4px 60px rgba(0,0,0,0.05000000074505806));
    left: 243px;
    top: -125px;
    position: relative;
    padding-bottom: 20px;
    border: none
}
.emailUISubject{
    font-size: 40px;
    font-weight: 700;
}
@media screen and (max-width: 600px) {
  .emailUI {
    width: 400px;
    margin-top: 2%;
  }
  .cardFormText{
    font-size: 10px;
}

  .emailUILogo{
    width: 250px;
  }
}