

    .iframe-container {
        position: relative;
        width: 90%;
        /* Adjust the height to maintain the aspect ratio (e.g., 16:9) */
        padding-bottom: 56.25%; /* 16:9 aspect ratio (height/width * 100) */
        overflow: hidden;
        margin-left: 2%;
        margin-right: 2%;
         border-radius: 10px;
    }

    .iframe-container iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
         border-radius: 10px;
    }