.home-body{
    background: rgb(254,254,254);
    background: linear-gradient(126deg, rgba(254,254,254,1) 0%, rgba(64,114,241,1) 117%);
}
.text{
    position:sticky;
    top: 20%;
}
.title {
    font-family: 'Lora', serif;
    font-size: 50px;
    font-weight: 500;
    /* background-color: aqua; */
} 
.image {
    width: 600px;
    height: 459px;
    margin: 10px;
    padding: 10px;
    margin-top:-13cm;
    margin-left: 19cm;
}
.subtitle{
    font-family: 'Lora', serif;
    font-size: 22px;
    font-weight: 200;
    color: #69697C;
    margin-top: 30px;

}
.homeSubtitle{
    font-family: 'Lora', serif;
    font-size: 22px;
    font-weight: 200;
    color: #69697C;
    margin-top: 30px;
}
.button{
    /* padding: 10px; */
    /* display:flex; */
    margin-top: 17px;
    gap:10px;
    
}
.btn-outline-danger{
  background: none;
  border-width: 2px;
}

  .btn-outline-secondary{
    border-color: black;
    color: black;
    border-width: 2px;
  }
  .btn-outline-secondary:hover {
      background-color: black;
      color: white;
      border-color: black;
    }

.main-rocket{
    height: 640px;
    width: 580px;
}

.footer{
    position: sticky;
    bottom: 0;
}

@media screen and (max-width: 992px) {
    .main-rocket{
    height: 0px;
    width: 0px;
}
    .title{
        font-size: 40px;
    }
    .homeSubtitle{
        font-size: 18px;
    }
}
