.newsletter-card{
    position: sticky;
    border: none;
    justify-content: center;
    align-items: center;
    font-family: 'Lora', serif;
}
.submit{
    width: 100%;
}

@media screen and (max-width: 992px) {
    .newsletter-card{
    width: 100%;
}
.submit{
    margin-top: 30px;
    width: 100%;
}
}
