.sidebar-wrapper {
  background-color: #4365cd;
  height: 100vh;
  position: sticky;
  top: 0;
  width: 10%;
}

.content-wrapper {
 width: 90%;
}


@media (min-width: 992px) {
  .mobile-row {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .sidebar-wrapper {
    width: 20%;
    float: left;
  }

  .content-wrapper {
    width: 80%;
    float: right;
  }
}